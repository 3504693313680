<template>
  <img
    :alt="`${$t.brand} ${$t.home}`"
    class="forced-colors:prefer-dark:invert forced-colors:prefer-light:invert-0"
    :class="{
      'filter invert': invert,
    }"
    data-test-id="vf-logo"
    :height="sizes[variant]?.height"
    :src="`/img/logos/${brand}/${variant}.svg`"
    :width="sizes[variant]?.width"
  >
</template>

<script lang="ts" setup>
import type { LogoVariants } from '#types/components/vf/logo'

withDefaults(defineProps<{
  variant?: LogoVariants
  invert?: boolean
}>(), {
  variant: 'default'
})

const { brand } = useRuntimeConfig().public
const { sizes = {} } = useAppConfig().components.vf.logo || {}
</script>
